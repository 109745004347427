<template>
  <k-basic-modal-layout>
    <template v-slot:header>
      <div class="panel-header">
        <div class="d-flex align-items-center pr-20">
          <span class="title-border bg-blue-cyan mr-20"></span>
          <h1 class="hasab-title">Új projekt</h1>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <b-form :id="formId">
        <div class="container px-2">
          <div class="row no-gutters">
            <div class="col-12 pr-2">
              <b-form-group label="Név">
                <k-basic-text-input
                  v-model="$v.form.Nev.$model"
                  :validator="$v.form.Nev"
                  autofocus
                ></k-basic-text-input>
              </b-form-group>
            </div>
            <div class="col-12">
              <b-form-group label="Azonosító">
                <k-basic-text-input
                  v-model="$v.form.Azonosito.$model"
                  :validator="$v.form.Azonosito"
                ></k-basic-text-input>
                <b-form-text>
                  A projekt rövid azonosítója. Kisbetű, nagybetű, szám, aláhúzás
                  és kötőjel használható
                </b-form-text>
              </b-form-group>
            </div>
          </div>
        </div>
      </b-form>
    </template>
    <template v-slot:footer>
      <div class="footer justify-content-between">
        <k-button
          variant="dark"
          label="Mégsem"
          @click="Close()"
          icon="fas fa-times"
        ></k-button>
        <k-button
          class="ml-2"
          variant="primary"
          label="Mehet"
          :form="formId"
          icon="fa-save"
          :loading="formSaving"
          @click="Mentes"
        ></k-button>
      </div>
    </template>
  </k-basic-modal-layout>
</template>

<script>
import required from 'vuelidate/src/validators/required';
import { NotificationFunctions } from '../../functions/NotificationFunctions';
import { projektService } from '../../services/ProjektService';
import { useModalHelper } from '../../utils/modal';
import { azonosito } from '../../utils/validations';

export default {
  name: 'uj-projekt-modal',
  data() {
    return {
      formSaving: false,
      form: {
        Nev: '',
        Azonosito: '',
      },
    };
  },
  setup(props, context) {
    let { formId, IsVueFormValid } = useModalHelper(props, context);
    return { formId, IsVueFormValid };
  },
  async mounted() {
    this.$emit('set-loading', true);
    this.$emit('set-loading', false);
  },

  created() {},
  methods: {
    Close() {
      this.$emit('close');
    },
    async Mentes() {
      if (!this.IsVueFormValid(this)) {
        console.log(this.$v);
        return;
      }
      this.formSaving = true;
      try {
        await projektService.CreateProjekt(this.form);
        if (this.$route.name != 'ProjectConfig') {
          this.$router.push({ name: 'ProjectConfig' });
        }
        this.Close();
      } catch (error) {
        NotificationFunctions.AjaxError({ error });
      }
      this.formSaving = false;
    },
  },
  validations() {
    let validations = {
      form: {
        Nev: { required },
        Azonosito: { required, azonosito: azonosito() },
      },
    };
    return validations;
  },
  computed: {},
  watch: {},
  props: {
    modal: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
};
</script>
