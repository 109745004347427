'use strict';

import { AppFunctions } from '../functions/AppFunctions';
import settings from '../settings';
import store from '../store';
import { AppStoreTypes } from '../store/modules/app';
import { HttpContext } from '../utils/httpContext';
import { appService } from './AppService';
import { userService } from './UserService';

class ProjektService {
  async GetProjektConfigForm() {
    const url = settings.baseUrl + 'Api/Projekt/GetProjektConfigForm';
    const result = await HttpContext.post({ url });
    return result;
  }

  async SaveProjektConfigForm(form) {
    const url = settings.baseUrl + 'Api/Projekt/SaveProjektConfigForm';
    const result = await HttpContext.post({ url, data: form });
    await appService.GetSzervezetek();
    AppFunctions.GetInitData();
    // store.dispatch(AppStoreTypes.user.set, { value: result });
    return result;
  }
  async CreateProjekt(form) {
    const url = settings.baseUrl + 'Api/Projekt/CreateProjekt';
    const result = await HttpContext.post({ url, data: form });
    await userService.ProjektValtas(result);
    store.dispatch(AppStoreTypes.jogosultProjektek.add, {
      value: { Id: result, Nev: form.Nev },
    });
    return result;
  }
  async GetContents() {
    const url = settings.baseUrl + 'Api/Projekt/GetContents';
    const result = await HttpContext.post({ url });
    return result;
  }
  async SaveContents(form) {
    const url = settings.baseUrl + 'Api/Projekt/SaveContents';
    const result = await HttpContext.post({ url, data: form });
    return result;
  }
}

let instance = new ProjektService();
export { instance as projektService };
